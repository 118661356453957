<template>
  <v-container style="margin-top: 50px">
    <v-row justify="center" v-for="(video, index) in videos" :key="index">
      <v-col cols="12" sm="12" style="text-align: center; width:fit-content" v-show="lang==video.language||video.language=='all'">
        <v-card
          elevation="0"
          style="border: 2px solid #005677;  padding:10px; "
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          
        >
        <h3>{{video.title[lang]}} </h3>
          <iframe
            width="560"
            height="315"
            :src="video.VideoSrc"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  inject: ["videoRepo"],
  data() {
    return {
      videos: [],
    };
  },
  computed:{
      lang()
      {
          return this.$store.state.lang;
      }
  },
  mounted() {
    this.videoRepo.all().then((result) => {
      this.videos = result["data"];
      this.$store.commit("setoverlay", { overlay: false });
    });
  },
};
</script>
